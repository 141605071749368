import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormConfig } from '../../models/Form';
import LanguageUtils from '../../utils/LanguageUtils';
import IconRender, { PlatformIcons } from '../shared/icon/IconRender';
import UsersGroupSolidIcon from '../shared/icon/UsersGroupSolidIcon';
import { Heading, HeadingSize } from '../shared/text/Heading';
import { useCurrentClient } from '../../global-state/Clients';

type ResourceCardProps = {
  data: FormConfig;
};

const ResourceCard: FC<ResourceCardProps> = (props) => {
  const { data } = props;
  const currentClient = useCurrentClient((x) => x.value);
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/clients/${currentClient?.id}/resources/${data.id}${data.isOwnedByClient ? '?isLocal=true' : ''}`)}
      className="border-gray-5 relative flex h-[230px] w-[270px] transform cursor-pointer flex-col rounded-xl border-2 bg-white shadow-xl transition-transform duration-300 ease-in-out hover:scale-105"
    >
      <div className="relative flex h-[155px] items-center">
        {!data.isOwnedByClient && (
          <div className="bg-primary-1 absolute right-3 top-3 flex h-6 w-6 items-center rounded-full p-1">
            <UsersGroupSolidIcon className="w-4 text-white" />
          </div>
        )}
        <div className="bg-background-1 mx-auto w-fit rounded-full p-6">
          <IconRender type={data.iconCode || PlatformIcons.ACCOUNTABILITY_ICON} gradient className="h-10 w-10" />
        </div>
      </div>
      <div
        className="border-primary-2 flex h-[75px] items-center justify-center border-t-2 p-6 text-center"
        style={{ wordBreak: 'break-word', hyphens: 'auto' }}
      >
        <Heading size={HeadingSize.H3} actualSize={HeadingSize.H5} className="truncate font-medium">
          {LanguageUtils.getTranslation('title', data.translations)}
        </Heading>
      </div>
    </div>
  );
};

export default ResourceCard;
